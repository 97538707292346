<template>
  <!-- 直播间管理页面 -->
  <div class="container">
    <a-row>
      <a-col :span="24" class="right">
        <div class="right-content">
          <!-- 筛选 -->
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="40">
                <!-- 直播状态 -->
                <a-col :md="5" :sm="24">
                  <a-form-item label="直播状态">
                    <a-select v-model="queryParam.liveStreamingStatus" allow-clear placeholder="请选择">
                      <a-select-option :value="1">直播中</a-select-option>
                      <a-select-option :value="2">已结束</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <!-- 直播间ID -->
                <!-- <a-col :md="6" :sm="24">
                  <a-form-item label="直播间号">
                    <a-input v-model="queryParam.number" allow-clear placeholder="请输入" />
                  </a-form-item>
                </a-col> -->
                <!-- 主播昵称 -->
                <a-col :md="5" :sm="24">
                  <a-form-item label="关键词">
                    <a-input v-model="queryParam.keyword" allow-clear placeholder="关键词搜索" />
                  </a-form-item>
                </a-col>
                <a-col :md="4" :sm="24">
                  <a-button type="primary" @click="initTableData">搜索</a-button>
                  <a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!-- 表格 -->
          <a-table :columns="columns" :data-source="data" :scroll="{ x: 1300 }" :loading="loading" :pagination="false">
            <!-- 直播状态插槽 -->
            <span slot="liveStreamingStatus" slot-scope="text">
              <span :style="{ color: text == 1 ? '#00de00' : text == 2 ? 'red' : '' }">●</span>&nbsp;
              <span>{{ text == 1 ? '直播中' : text == 2 ? '已结束' : '' }}</span>
            </span>
            <!-- 账号状态插槽 -->
            <span slot="accountStatus" slot-scope="text">
              <a-tag :color="text == 1 ? 'green' : text == 2 ? 'red' : ''">
                {{ text == 1 ? '正常' : text == 2 ? '违规' : '' }}
              </a-tag>
            </span>
            <!-- 操作插槽 -->
            <span slot="action" slot-scope="text, record">
              <a @click="$refs.detailForm.detail(record)">详情</a>
              <a-divider type="vertical" />
              <router-link
                :to="{
                  path: '/giftDetail',
                  query: {
                    liveId: record.id,
                  },
                }"
                >礼物明细</router-link
              >
              <a-divider type="vertical" />
              <router-link
                :to="{
                  path: '/livecommodityadmin',
                  query: {
                    id: record.id,
                    liveStatus: record.liveStreamingStatus,
                    role: record.role,
                  },
                }"
                >商品管理</router-link
              >
              <span v-if="record.liveStreamingStatus == 1">
                <a-divider type="vertical" />
                <a-popconfirm placement="topRight" title="确认终止？" @confirm="() => $refs.stopForm.detail(record)">
                  <a>终止</a>
                </a-popconfirm>
              </span>
            </span>
          </a-table>
          <!-- 分页组件 -->
          <div style="margin-top: 20px; display: flex; flex-direction: row-reverse">
            <a-pagination
              :pageSize="page.pageSize"
              :current="page.pageNo"
              show-size-changer
              :page-size-options="['10', '20', '50', '100']"
              show-quick-jumper
              :total="total"
              :show-total="(total) => `共 ${total} 条`"
              @change="pageChange"
              @showSizeChange="sizeChange"
            ></a-pagination>
          </div>
        </div>
      </a-col>
    </a-row>
    <detail-form ref="detailForm"></detail-form>
    <stop-form ref="stopForm" @ok="initTableData"></stop-form>
  </div>
</template>

<script>
import { liveAdminList, liveAdminEnd } from '@/api/modular/mallLiving/liveRoomAdmin'

import detailForm from './detailForm.vue'
import stopForm from './stopForm.vue'

const columns = [
  {
    title: '序号',
    align: 'left',
    width: '100px',
    customRender: (text, record, index) => `${index + 1}`,
  },
  // {
  //   title: '直播间号',
  //   align: 'left',
  //   dataIndex: 'number',
  //   key: 'number'
  // },
  {
    title: '直播间标题',
    dataIndex: 'title',
    align: 'left',
  },
  {
    title: '主播昵称',
    dataIndex: 'nickName',
    key: 'nickName',
    align: 'left',
  },
  {
    title: 'ID号',
    dataIndex: 'idNo',
    align: 'left',
  },
  {
    title: '手机号',
    dataIndex: 'mobile',
    key: 'mobile',
    align: 'left',
  },

  {
    title: '直播间分类',
    dataIndex: 'broadcastCategoryName',
    key: 'broadcastCategoryName',
    align: 'left',
  },
  {
    title: '直播状态',
    dataIndex: 'liveStreamingStatus',
    key: 'liveStreamingStatus',
    align: 'left',
    scopedSlots: { customRender: 'liveStreamingStatus' },
  },
  {
    title: '产币',
    dataIndex: 'coin',
    key: 'coin',
    align: 'left',
  },
  {
    title: '账号状态',
    dataIndex: 'accountStatus',
    key: 'accountStatus',
    align: 'left',
    scopedSlots: { customRender: 'accountStatus' },
  },
  {
    title: '直播时长',
    width: '150px',
    dataIndex: 'time',
    align: 'left',
  },
  {
    title: '操作',
    width: '250px',
    align: 'left',
    dataIndex: 'action',
    fixed: 'right',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
]

export default {
  data() {
    return {
      storeId: '',
      columns,
      data: [],
      page: {
        pageNo: 2,
        pageSize: 10,
      },
      total: 0,
      loading: false,
      queryParam: {},
    }
  },
  components: {
    detailForm,
    stopForm,
  },
  mounted() {
    this.storeId = this.$route.query.id
    this.initTableData()
  },
  methods: {
    pageChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    sizeChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    initTableData() {
      this.page.pageNo = 1
      this.getList()
    },
    //终止直播
    sysItemEnd(record) {
      liveAdminEnd({ id: record.id }).then((res) => {
        if (res.success) {
          this.getList()
          this.$message.success('终止成功！')
        }
      })
    },
    getList() {
      this.loading = true
      let query = {
        queryParam: {
          ...this.queryParam,
          storeId: this.storeId,
        },
        page: this.page,
      }
      liveAdminList(query)
        .then((res) => {
          if (res.success) {
            this.data = res.data.rows
            this.total = res.data.totalRows
            this.loading = false
          }
        })
        .finally((res) => {
          setTimeout(() => {
            this.loading = false
          }, 5000)
        })
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  min-width: 1000px;
}
.title {
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-weight: bold;
}
.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}
.right-content {
  box-sizing: border-box;
  padding: 10px;
}
.icon {
  font-size: 20px;
  margin: 0 5px;
}
img {
  width: 20px;
  height: 20px;
}
</style>
